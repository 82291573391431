.date-picker.MuiCalendarPicker-root {
  width: 100%;
  margin: 0 auto;
  color: var(--color-on-surface);
  background-color: var(--color-surface);
  font-size: 16px;
  border-radius: 8px;
  & .MuiIconButton-root {
    color: var(--color-on-surface);
  }
  & .MuiTypography-root {
    color: var(--color-on-surface);
    font-size: 16px;
	flex-grow: 1;
  }
  & .PrivatePickersFadeTransitionGroup-root {
    font-size: 16px;
  }
}
