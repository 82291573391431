:root {
  --color-background: #0b0f19;
  --color-surface: #303044;
  --color-surface-variant: #1f1f2d;
  --color-on-surface: #ffffff;
  --color-primary: #ef6c00;
  --color-primary-variant: #e65000;
  --color-on-primary: #ffffff;
  --font-family: "Noto Sans TC", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: var(--color-background); */
  font-family: var(--font-family);
  overflow-y: scroll;
}
html {
  /* font-size: 62.5%; */
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
