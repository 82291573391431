.login-container {
  display: flex;
  width: 100%;
  height: calc(100vh + 100px);
}

.login-image {
  width: 100%;
  /* height: 100%; */
  background: linear-gradient(270deg, #0b0f19 0%, rgba(11, 15, 25, 0.2) 52.08%, rgba(11, 15, 25, 0) 100%), url(./login-image.webp);
  /* background-size: 50vw; */
  background-size: cover;
  background-repeat: no-repeat;
}

.login-info {
  /* display: flex; */
  /* row-gap: 16px; */
  /* flex-direction: column; */
  position: absolute;
  align-items: stretch;
  top: 200px;
  width: 280px;
  left: calc((100% - 280px) / 2);
}
.right-panel {
  width: 50vw;
  display: flex;
}

.login-buttom {
  position: absolute;
  top: 424px;
  left: calc((100% - 280px) / 2);
  width: 280px;
  height: 40px;
  font-size: 16px;
}
.right-panel-content {
  position: relative;
  top: 100px;
  background-color: var(--color-surface);
  height: 676px;
  min-width: 320px;
  max-width: 466px;
  border-radius: 8px;
  margin: 0 auto;
}

#login-title {
  position: absolute;
  top: 100px;
  font-size: 24px;
  color: #ffffff;
  left: calc((100% - 280px) / 2);
  margin: 0px;
  width: 280px;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .login-image {
    display: none;
  }
}
@media screen and (max-height: 776px) {
  .login-container {
    height: 876px;
  }
}